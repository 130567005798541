


























































































































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BFormRadio,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  DaisyEntrepriseCreateModel,
  EntrepriseCreateModel,
  RessifnetEntrepriseCreateModel,
} from "@/api/models/adherents/adherent";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";
import { CodeNaf } from "@/api/models/codesNaf/codeNaf";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "create-adherent",
})
export default class CreateAdherent extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;
  @Prop({ required: true, type: Boolean })
  isAddNewAdherentSidebarActive!: boolean;
  loading = false;

  required = required;

  newAdherent: EntrepriseCreateModel = {
    libelle: "",
    expandRessifnet: false,
    expandDaisy: false
  };
  listSecteursActivite = new PaginatedList<any>();
  listSecteursActiviteDaisy = new PaginatedList<any>();

  codesNaf: PaginatedList<CodeNaf> = new PaginatedList<CodeNaf>()

  categoriesAdherent: string[] = [];
  totalCountCategoriesAdherent = 0;

  async created() {
    await this.loadCategoriesAdherents();
    await this.searchSecteursActivite(null);
    await this.searchSecteursActiviteDaisy(null);
  }

  async searchSecteursActivite(params: any) {
    if (!params || params?.reset) {
      this.listSecteursActivite = new PaginatedList<any>();
    }

    await this.$http.ressifnet.secteursActivite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 50,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        this.listSecteursActivite = response;
      });
  }
  
  async searchSecteursActiviteDaisy(params: any) {
    if (!params || params?.reset) {
      this.listSecteursActiviteDaisy = new PaginatedList<any>();
    }

    await this.$http.daisy.secteursActivite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 50,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        this.listSecteursActiviteDaisy = response;
      });
  }

  async loadCategoriesAdherents() {
    await this.$http.myressif.parametres
      .getByNom("Catégories d'Adhérents")
      .then((res: any) => {
        console.log(res);
        if (res.valeur) {
          let categories = JSON.parse(res.valeur);
          this.categoriesAdherent.push(...categories);
        }
      });
  }

  async searchCodesNaf(params: any) {
    await this.$http.myressif.codesNaf
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<CodeNaf>) => {
        this.codesNaf.items = this.codesNaf!.items.concat(
            response.items.filter(
              (ri) => !this.codesNaf!.items.some((cn) => cn.code == ri.code)
            ).map((x: CodeNaf) => <CodeNaf>{
          ...x,
          completeLabel: `${x.code} - ${x.libelle}`
        })
      )});
  }

  resetForm() {
    this.newAdherent = {
      libelle: "",
      expandRessifnet: false,
      expandDaisy: false
    };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .post(this.newAdherent)
      .then(
        async (response: any) => {
          if (this.newAdherent.expandRessifnet) {
            let newMapping: RessifnetEntrepriseCreateModel = {
              entrepriseId: response,
              secteurActiviteRessifnetId:
                this.newAdherent.secteurActiviteRessifnetId,
            };
            await this.$http.ressifnet.entreprises.post(newMapping).then(
              (response: any) => {
                successAlert.fire({
                  title: "Création d'un adhérent",
                  text: "Adhérent créé ici et dans Ressifnet avec succès",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  confirmButtonText: "D'accord, merci !",
                });
              },
              (error: any) => {
                this.$swal({
                  title: "Attention !",
                  text: "L'entreprise a bien été créée, mais n'a pas pu être ajoutée à Ressifnet",
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                  confirmButtonText: "D'accord, merci !",
                });
              }
            );
          }

          if (this.newAdherent.expandDaisy) {
            let newMapping: DaisyEntrepriseCreateModel = {
              entrepriseId: response,
              secteurActiviteDaisyId:
                this.newAdherent.secteurActiviteDaisyId,
            };
            await this.$http.daisy.entreprises.post(newMapping).then(
              (response: any) => {
                successAlert.fire({
                  title: "Création d'un adhérent",
                  text: "Adhérent créé ici et dans Daisy avec succès",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  confirmButtonText: "D'accord, merci !",
                });
              },
              (error: any) => {
                this.$swal({
                  title: "Attention !",
                  text: "L'entreprise a bien été créée, mais n'a pas pu être ajoutée à Daisy",
                  icon: "warning",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                  confirmButtonText: "D'accord, merci !",
                });
              }
            );
          }
          
          if (!this.newAdherent.expandDaisy && !this.newAdherent.expandRessifnet) {
            successAlert.fire({
              timer: 3500,
              title: "Création d'un adhérent",
              text: "Adhérent créé dans MyRessif avec succès",
            });
          }
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-adherent-sidebar-active", false);
        this.loading = false;
      });
  }
}
