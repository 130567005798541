

























































































































































































































import { Entreprise } from "@/api/models/adherents/adherent";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { StatusEnum } from "@/api/models/enums/statusEnum";
import { TypeContratEnum } from "@/api/models/enums/typeContratEnum";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BAlert
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import CreateAdherent from "./Create.vue";

@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    CreateAdherent,
    BFormSelect,
    BBadge,
  },
})
export default class ListAdherents extends Vue {
  status = StatusEnum;
  typesContrat = TypeContratEnum
  perPage = 10;
  pertinentOnly = false;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  timeout: any = null;
  searchQuery = "";
  isSortDirDesc = false;
  sortBy = "libelle";
  isAddNewAdherentSidebarActive = false;
  sortStatut: any = null

  tableColumns = [
    { key: "status", label: "statut", sortable: false },
    { key: "libelle", sortable: true },
    { key: "siret", sortable: true },
    { key: "adresse", sortable: true },
    { key: "ville", sortable: true },
    { key: "codePostal", sortable: true },
    { key: "pertinentLabel", sortable: true, label : "Pertinent" }
  ];
  paginatedListAdherents = new PaginatedList<Entreprise>();

  async created() {
    await this.loadListAdherents({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListAdherents.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListAdherents.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadListAdherents({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  @Watch('searchQuery')
  @Watch("sortBy")
  @Watch("isSortDirDesc")
  @Watch("pertinentOnly")
  @Watch("sortStatut")
  async searchAdherent() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListAdherents({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
        search: this.searchQuery,
        pertinentOnly: this.pertinentOnly,
        sortStatut: this.sortStatut
      })
    }, 1000);
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      pertinentOnly: this.pertinentOnly
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListAdherents(params);
  }

  async loadListAdherents(params: any) {
    if (!params || params?.reset) {
      this.paginatedListAdherents = new PaginatedList<Entreprise>();
    }

    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.paginatedListAdherents = response;
      });
  }

  async askDeleteAdherent(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cet adhérent ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.entreprises.delete(id).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un adhérent",
          text: "Suppression effectuée avec succès",
        });
        await this.loadListAdherents(null);
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  onRowSelected(record: any) {
    this.$router.push({ name: "details-adherent", params: { id: record.id } });
  }

}
